import { GetServerSideProps } from 'next';
import { Fragment } from 'react';

import { wrapper } from 'src/redux/store/store';
import { getSimplePage } from 'src/api/pageApi';
import { PageSetUpInStorageConfig, ServerSideRenderingContext } from 'types/objectTypes';
import { CustomerType } from 'constants/enums';
import { calculateCustomerType } from 'lib/session.service';
import { extractSlug, setUpPageInStore } from 'lib/page.service';
import withMiddleware, { commonMiddleware, postCommonMiddleware } from 'src/shared/lib/middleware/combiner';

const handler = async ({
  // @ts-ignore
  locale,
  path,
  req,
  store,
  preview = false,
}: ServerSideRenderingContext): Promise<any> => {
  const session = req?.session?.getSession();
  const customerType = calculateCustomerType(req.session);

  const isAuthorized = customerType === CustomerType.EC;
  try {
    const slug = extractSlug(req.url, locale);

    if (!slug) {
      return { notFound: true };
    }

    const page = await getSimplePage(
      {
        slug,
        customerType,
        preview,
        lowLevelContentLength: 12,
        topLevelContentLength: 4,
        path,
      },
    );

    if (page) {
      const pageSetUpConfig: PageSetUpInStorageConfig = {
        page,
        session,
        slug,
        store,
        headers: req.headers,
        isAuthorized,
      };
      setUpPageInStore(pageSetUpConfig);

      return { props: {} };
    }

    return { notFound: true };
  } catch (e) {
    console.error(e);

    return { props: {} };
  }
};

export const getServerSideProps: GetServerSideProps = wrapper
// @ts-ignore
  .getServerSideProps(withMiddleware(
    handler,
    { prior: commonMiddleware, post: postCommonMiddleware },
  ));

export default Fragment;
